import { LumEnvironmentInterface } from '@lum-types';
import { LUM_LOG_LEVEL } from '@lum-utils';
import packageInfo from '../../package.json';

export declare const LUM_BUILD_TIMESTAMP: number;

export const LUM_ENV: LumEnvironmentInterface = {
  production: true,
  env: 'staging',
  version: packageInfo?.version,
  buildTimestamp: LUM_BUILD_TIMESTAMP,
  logLevel: LUM_LOG_LEVEL.Warning,
  api: {
    baseUrl: 'https://api.staging.connect.lumeso.com',
    docsUrl:
      'https://docs.staging.connect.lumeso.com/site/connect/0.1.0/index.html',
  },
  dcupl: {
    baseUrl: 'https://api.staging.connect.lumeso.com/dcupl',
    applicationKey: 'connect-lumeso',
  },
  cognito: {
    userPoolClientId: '51h8al6v3snn4d84t4dosfn6qe',
    userPoolId: 'eu-central-1_8sQevxfeA',
    loginWith: {
      oauth: {
        domain:
          'lumeso-authorizers-staging.auth.eu-central-1.amazoncognito.com',
        scopes: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: ['https://staging.connect.lumeso.com/'],
        redirectSignOut: ['https://staging.connect.lumeso.com/login'],
        responseType: 'code',
      },
    },
  },
};
